import Warehouse from "../images/buildings/buldingDetails/Warehouse.png";
import Embassy from "../images/buildings/buldingDetails/Embassy.png";
import Marketplace from "../images/buildings/buldingDetails/Marketplace.png";
import Barracks from "../images/buildings/buldingDetails/Barracks.png";
import Brothel from "../images/buildings/buldingDetails/Brothel.png";
import Mapchamber from "../images/buildings/buldingDetails/MapChamber.png";
import Casino from "../images/buildings/buldingDetails/Casino.png";
import Quarry from "../images/buildings/buldingDetails/Quarry.png";
import Mill from "../images/buildings/buldingDetails/Mill.png";
import Lumberyard from "../images/buildings/buldingDetails/Lumberyard.png";
import Smith from "../images/buildings/buldingDetails/Blacksmith.png";
import Church from "../images/buildings/buldingDetails/Church.png";
import CaligulasPalace from "../images/buildings/buldingDetails/Caligula'sPalace.png";

export function BuildingInfoImage({ type, isDetails }) {
  let image = null;

  if (type === "Warehouse") {
    image = Warehouse;
  }

  if (type === "Barracks") {
    image = Barracks;
  }

  if (type === "Casino") {
    image = Casino;
  }

  if (type === "Marketplace") {
    image = Marketplace;
  }

  if (type === "Embassy") {
    image = Embassy;
  }

  if (type === "Mapchamber") {
    image = Mapchamber;
  }

  if (type === "Mill") {
    image = Mill;
  }
  if (type === "Smith") {
    image = Smith;
  }
  if (type === "Lumberyard") {
    image = Lumberyard;
  }
  if (type === "Quarry") {
    image = Quarry;
  }
  if (type === "Brothel") {
    image = Brothel;
  }

  if (type === "Church") {
    image = Church;
  }
  if (type === "Caligula's Palace") {
    image = CaligulasPalace;
  }

  if (type === "Wall") {
    image = Casino;
  }

  if (image === null) {
    return <div>Building image not found</div>;
  }

  if (isDetails) {
    return (
      <img
        src={image}
        alt="Building image"
        className="w-[30vw] md:w-[17vw] min-w-[180px]"
      />
    );
  }

  return (
    <img
      src={image}
      alt="Building image"
      className={type === "Wall" ? "w-12 h-fit" : undefined}
    />
  );
}
