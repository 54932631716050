import { useContext, useEffect, useState } from "react";
import {
  CityConfigContext,
  UserContext,
  WarehouseContext,
} from "../../index.jsx";
import { CONSTANTS } from "../../constants.js";
import { WheatIconResponsive } from "../../images/resources/WheatIconResponsive";
import { WoodIconReponsive } from "../../images/resources/WoodIconReponsive";
import { StoneIconResponsive } from "../../images/resources/StoneIconResponsive";
import { IronIconResponsive } from "../../images/resources/IronIconResponsive";
import { timerActions } from "../reducers/timerReducer";
import { useTimerDispatch } from "../contexts/timerContext";
import { ClubmanResponsive } from "../../images/troops/ClubmanResponsive";
import { SpearmanResponsive } from "../../images/troops/SpearmanResponsive";
import { AxemanResponsive } from "../../images/troops/AxemanResponsive";
import { FounderIconResponsive } from "../../images/troops/FounderIconResponsive";
import { HelperFunctions } from "../../HelperFunctions";
import { GuardianResponsive } from "../../images/troops/GuardianResponsive.jsx";
import { WarlordResponsive } from "../../images/troops/WarlordResponsive.jsx";

export function TroopCard(props) {
  const { warehouse } = useContext(WarehouseContext);
  const { inCityId } = useContext(CityConfigContext);
  const dispatch = useTimerDispatch();
  const { canMakeFoundersAmount } = useContext(CityConfigContext);

  const [canMakeTroop, setCanMakeTroop] = useState(0);
  const [maxOne, setMaxOne] = useState(0);
  const [error, setError] = useState("");
  const { setShowSpinner } = useContext(UserContext);

  async function makeTroops() {
    setError("");
    setMaxOne(0);
    setShowSpinner(true);

    if (maxOne === 0) {
      setError("You must select at least one");
    } else if (maxOne <= canMakeTroop) {
      if (
        props.troopId === 1 ||
        props.troopId === 2 ||
        props.troopId === 3 ||
        props.troopId === 5 ||
        props.troopId === 6
      ) {
        const res = await fetch(
          `${import.meta.env.VITE_GAMEHOST}/api/troops/make/${
            props.troopId
          }/${inCityId}/${maxOne}`,
          {
            credentials: "include",
          }
        );

        if (res.status === 200) {
          const data = await res.json();
          const now = new Date().getTime();
          data.timeLeft = Math.floor((data.end_time - now) / 1000);
          const timePassed = now - data.start_time;
          const remainingTimeForNextUnit = Math.ceil(
            (data.time_per_unit - (timePassed % data.time_per_unit)) / 1000
          );
          dispatch({
            type: timerActions.ADD_TIMER,
            category: "trainTroopsTimers",
            payload: {
              duration: data.totalTime,
              timeLeft: data.timeLeft,
              endTime: data.end_time,
              troopsId: data.troopsId,
              amount: data.amount,
              state: data.state,
              time_per_unit: data.time_per_unit,
              timeLeftNextUnit: remainingTimeForNextUnit,
            },
          });
        }
      } else if (props.troopId === 4) {
        const res = await fetch(
          `${import.meta.env.VITE_GAMEHOST}/api/founder/make/${
            props.troopId
          }/${inCityId}/${maxOne}`,
          {
            credentials: "include",
          }
        );

        if (res.status === 200) {
          const data = await res.json();
          const now = new Date().getTime();
          data.timeLeft = Math.floor((data.end_time - now) / 1000);
          const timePassed = now - data.start_time;
          const remainingTimeForNextUnit = Math.ceil(
            (data.time_per_unit - (timePassed % data.time_per_unit)) / 1000
          );
          dispatch({
            type: timerActions.ADD_TIMER,
            category: "trainFoundersTimers",
            payload: {
              duration: data.totalTime,
              timeLeft: data.timeLeft,
              endTime: data.end_time,
              troopsId: data.troopsId,
              amount: data.amount,
              state: data.state,
              time_per_unit: data.time_per_unit,
              timeLeftNextUnit: remainingTimeForNextUnit,
            },
          });
        }
      }
    } else {
      setError("You cannot afford that many");
    }
    setTimeout(() => {
      setShowSpinner(false);
    }, 1700);
  }

  useEffect(() => {
    const canMakeResOne = warehouse.wheat / props.troop.res_one_req;
    const canMakeResTwo = warehouse.wood / props.troop.res_two_req;
    const canMakeResThree = warehouse.stone / props.troop.res_three_req;
    const canMakeResFour = warehouse.iron / props.troop.res_four_req;

    if (
      props.troopId === 1 ||
      props.troopId === 2 ||
      props.troopId === 3 ||
      props.troopId === 5 ||
      props.troopId === 6
    ) {
      setCanMakeTroop(
        Math.floor(
          Math.min(
            canMakeResOne,
            canMakeResTwo,
            canMakeResThree,
            canMakeResFour
          )
        )
      );
    } else if (props.troopId === 4) {
      // CanMakeFoundersAmount gets calculated on the server, based on mapchamber level, how many cities are founded and how many founders currently are in queue on in city/not in city
      setCanMakeTroop(
        Math.floor(
          Math.min(
            canMakeResOne,
            canMakeResTwo,
            canMakeResThree,
            canMakeResFour,
            canMakeFoundersAmount
          )
        )
      );
    }
  }, [warehouse]);

  return (
    <div className="mt-2 p-2 rounded-lg border-2 border-black bg-cityCard1">
      <h3 className="text-lg mb-2 text-center">
        {props.troopNumber === 1 ? (
          CONSTANTS.TRIBE_ONE_TROOP_ONE
        ) : props.troopNumber === 2 ? (
          CONSTANTS.TRIBE_ONE_TROOP_TWO
        ) : props.troopNumber === 3 ? (
          CONSTANTS.TRIBE_ONE_TROOP_THREE
        ) : props.troopNumber === 4 ? (
          CONSTANTS.TRIBE_ONE_TROOP_FOUR
        ) : props.troopNumber === 5 ? (
          CONSTANTS.TRIBE_ONE_TROOP_FIVE
        ) : props.troopNumber === 6 ? (
          CONSTANTS.TRIBE_ONE_TROOP_SIX
        ) : (
          <></>
        )}
      </h3>
      <div className="flex flex-row sm:flex-row justify-center">
        <div className="flex justify-center">
          {props.troopNumber === 1 && <ClubmanResponsive />}
          {props.troopNumber === 2 && <SpearmanResponsive />}
          {props.troopNumber === 3 && <AxemanResponsive />}
          {props.troopNumber === 4 && <FounderIconResponsive />}

          {props.troopNumber === 5 && <GuardianResponsive />}

          {props.troopNumber === 6 && <WarlordResponsive />}
          <input
            type="number"
            value={maxOne}
            onChange={(e) => setMaxOne(parseInt(e.target.value))}
            max={canMakeTroop}
            min={0}
            className="border-2 border-gray-300 rounded px-2 py-1 mr-2 text-lg min-w-[12vw]  sm:min-w-[8vw] text-white"
          />
          <span
            className="cursor-pointer text-blue-600 hover:text-blue-500 text-xs md:text-sm"
            onClick={() => setMaxOne(canMakeTroop)}
          >
            ({canMakeTroop})
          </span>
        </div>

        <button
          className="bg-mainDarkBrown hover:bg-blue-500 text-white font-semibold py-2 px-2 sm:py-2 sm:px-4 rounded transition duration-200 ml-2 text-xs md:text-sm"
          onClick={makeTroops}
        >
          Train
        </button>
      </div>

      <div>
        {props.ad} Attack power / {props.dp} Defence power
      </div>

      <div>
        <div className="mt-2 grid place-items-center grid-cols-4">
          <div className="flex ml-2">
            <WheatIconResponsive />
            <h4>{props.troop.res_one_req}</h4>
          </div>
          <div className="flex ml-2">
            <WoodIconReponsive />
            <h4>{props.troop.res_two_req}</h4>
          </div>
          <div className="flex ml-2">
            <StoneIconResponsive />
            <h4>{props.troop.res_three_req}</h4>
          </div>
          <div className="flex ml-2">
            <IronIconResponsive />
            <h4>{props.troop.res_four_req}</h4>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        {HelperFunctions.secondsToTimestamp(props.troop.seconds_to_build)}
      </div>
      {error ? <div className="text-red-700 mt-2">{error}</div> : <></>}
    </div>
  );
}
