import { ResourcePriceCard } from "../cards/ResourcePriceCard";
import { ConfirmCoinSell } from "../modals/ConfirmCoinSell";
import { useContext, useEffect, useState } from "react";
import {
  CasinoContext,
  CityConfigContext,
  UserContext,
  WarehouseContext,
} from "../../index";
import { CasinoCoinResponsive } from "../../images/CasinoCoinResponsive";
import { CASINO_COINS_BUY_PRICE } from "../../RULES";

export function CasinoBuyIn() {
  const { currentCasinoCoins, blackJackDetails, maxCasinoCoins } =
    useContext(CasinoContext);
  const { warehouse } = useContext(WarehouseContext);
  const [purchaseAmount, setPurchaseAmount] = useState(0);
  const [sellAmount, setSellAmount] = useState(0);
  const [canBuyAmount, setCanBuyAmount] = useState(0);
  const [status, setStatus] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { setShowSpinner } = useContext(UserContext);

  async function sellCoins() {
    setShowSpinner(true);
    setStatus("");
    setShowModal(false);
    if (sellAmount > currentCasinoCoins) {
      setStatus("You do not have that many coins!");
      return;
    }

    if (sellAmount <= 0) {
      setStatus("Please select some coins!");
      return;
    }

    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/casino/sell/${sellAmount}`,
      { credentials: "include" }
    );

    if (res.status !== 200) {
      const data = await res.text();
      setStatus(data);
    } else {
      setShowModal(false);
      setSellAmount(0);
    }
    setTimeout(() => {
      setShowSpinner(false);
    }, 1700);
  }

  async function buyCoins() {
    setStatus("");

    if (purchaseAmount <= 0) {
      setStatus("You need to choose an amount!");
      return;
    }

    if (purchaseAmount > canBuyAmount) {
      setStatus("You cant buy that many coins at the moment!");
      return;
    }

    const totalWhatPrice = CASINO_COINS_BUY_PRICE.Wheat * purchaseAmount;
    const totalWoodPrice = CASINO_COINS_BUY_PRICE.Wood * purchaseAmount;
    const totalStonePrice = CASINO_COINS_BUY_PRICE.Stone * purchaseAmount;
    const totalIronPrice = CASINO_COINS_BUY_PRICE.Iron * purchaseAmount;
    if (totalWhatPrice > warehouse.wheat) {
      setStatus("You dont have enough wheat for that!");
      return;
    }
    if (totalWoodPrice > warehouse.wood) {
      setStatus("You dont have enough wood for that!");
      return;
    }
    if (totalStonePrice > warehouse.stone) {
      setStatus("You dont have enough stone for that!");
      return;
    }
    if (totalIronPrice > warehouse.iron) {
      setStatus("You dont have enough iron for that!");
      return;
    }
    setShowSpinner(true);
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/casino/buy/${purchaseAmount}`,
      { credentials: "include" }
    );
    setPurchaseAmount(0);

    if (res.status !== 200) {
      const error = await res.text();
      setStatus(error);
    }
    setTimeout(() => {
      setShowSpinner(false);
    }, 1700);
  }

  function onPurchaseChange(e) {
    setPurchaseAmount(e);
  }

  function onSellChange(e) {
    setSellAmount(e);
  }

  useEffect(() => {
    let totalCoins = currentCasinoCoins;

    if (blackJackDetails) {
      totalCoins += blackJackDetails.buy_in_amount;
    }

    let coinsFromWheat = Math.floor(
      warehouse.wheat / CASINO_COINS_BUY_PRICE.Wheat
    );
    let coinsFromWood = Math.floor(
      warehouse.wood / CASINO_COINS_BUY_PRICE.Wood
    );
    let coinsFromStone = Math.floor(
      warehouse.stone / CASINO_COINS_BUY_PRICE.Stone
    );
    let coinsFromIron = Math.floor(
      warehouse.iron / CASINO_COINS_BUY_PRICE.Iron
    );

    let canBuyFromResources = Math.min(
      coinsFromWheat,
      coinsFromWood,
      coinsFromStone,
      coinsFromIron
    );

    let maximumCoinsPlayerCanHold = maxCasinoCoins - totalCoins;

    let finalValue = Math.min(canBuyFromResources, maximumCoinsPlayerCanHold);

    if (finalValue < 0) {
      setCanBuyAmount(0);
    } else {
      setCanBuyAmount(finalValue);
    }
  }, [currentCasinoCoins, blackJackDetails, warehouse]);

  return (
    <div>
      <div className="flex justify-center flex-col lg:flex-row">
        {/*PURCHASE COINS*/}
        <div className="bg-mainLightBrown1 flex flex-col items-center ml-4 p-2">
          <h4 className="text-xl font-semibold mb-2 text-center">
            Purchase coins
          </h4>

          <ResourcePriceCard priceType="buy" />

          <div className="mt-2">
            <input
              min={0}
              max={canBuyAmount}
              value={purchaseAmount}
              type="number"
              className="w-[5vw] min-w-[50px] bg-mainLightBrown3"
              onChange={(e) => onPurchaseChange(e.target.value)}
            />
            <span
              className="cursor-pointer text-mainLightBrown3 hover:text-blue-500 m-1"
              onClick={() => setPurchaseAmount(canBuyAmount)}
            >
              ({canBuyAmount})
            </span>
            <button
              className="bg-green-600 hover:bg-green-500 text-white font-semibold py-2 px-4 rounded transition duration-200 ml-2"
              onClick={buyCoins}
            >
              Buy
            </button>
          </div>
        </div>

        {/*SELL COINS*/}
        <div className="bg-mainLightBrown3 flex flex-col items-center ml-4 p-2 text-white mt-4 lg:mt-0">
          <h4 className="text-xl font-semibold mb-2 text-black text-center">
            Sell coins
          </h4>

          <ResourcePriceCard priceType="sell" />

          <div className="mt-2">
            <input
              min={0}
              max={currentCasinoCoins}
              value={sellAmount}
              type="number"
              className="w-[5vw] bg-mainLightBrown1 text-black min-w-[50px]"
              onChange={(e) => onSellChange(e.target.value)}
            />
            <span
              className="cursor-pointer text-mainLightBrown1 hover:text-blue-500 ml-1"
              onClick={() => setSellAmount(currentCasinoCoins)}
            >
              ({currentCasinoCoins})
            </span>
            <button
              className="bg-green-600 hover:bg-green-500 text-white font-semibold py-2 px-4 rounded transition duration-200 ml-2"
              onClick={() => setShowModal(true)}
            >
              Sell
            </button>
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-2">
        <CasinoCoinResponsive />
        <h3>
          {currentCasinoCoins}/{maxCasinoCoins}
        </h3>
      </div>

      {status && <div className="text-red-500">{status}</div>}

      {showModal && (
        <ConfirmCoinSell
          onConfirm={sellCoins}
          onCancel={() => setShowModal(false)}
          amountOfCoins={sellAmount}
        />
      )}
    </div>
  );
}
