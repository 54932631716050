import { useContext, useEffect, useState } from "react";
import { CityConfigContext } from "../../index";
import { CityBuildingSpot } from "./CityBuildingSpot";
import { useTimerState } from "../contexts/timerContext";
import stoneTexture from "../../images/textures/stoneTexture.jpg";

export function TownCenter() {
  const { cityTownCenter, inCityId } = useContext(CityConfigContext);
  const [cityWall, setCityWall] = useState();

  const [sortedBuildings, setSortedBuildings] = useState([]);
  const globalTimers = useTimerState();
  const [buildingTimers, setBuildingTimers] = useState(
    globalTimers.buildingTimers
  );

  function updateBuildingStatus() {
    if (buildingTimers.length > 0 && cityTownCenter) {
      const currentTime = new Date().getTime();
      buildingTimers.forEach((timerItem) => {
        let highestLvl = 0;
        cityTownCenter.forEach((buildingItem) => {
          if (parseInt(timerItem.cityId) === parseInt(inCityId)) {
            if (timerItem.location === buildingItem.location) {
              if (timerItem.extra.next_level > highestLvl) {
                highestLvl = timerItem.extra.next_level;
              }
              if (
                timerItem.endTime >= currentTime &&
                highestLvl !== buildingItem.building_level
              ) {
                buildingItem.status = `${buildingItem.building_level} -> ${highestLvl}`;
                buildingItem.nextLevel = highestLvl;
              }
            }
          }
        });
      });
    }
  }

  useEffect(() => {
    if (globalTimers.buildingTimers.length > 0) {
      setBuildingTimers(globalTimers.buildingTimers);
    }
  }, [globalTimers]);

  useEffect(() => {
    if (cityTownCenter) {
      updateBuildingStatus();
      cityTownCenter.sort((a, b) => a.location - b.location);
      const temp = [];
      cityTownCenter.forEach((item) => {
        if (item.building_name === "Wall") {
          setCityWall(item);
        } else {
          temp.push(item);
        }
      });
      setSortedBuildings(temp);
    }
  }, [cityTownCenter, buildingTimers]);

  if (!sortedBuildings) {
    return <div>Loading..</div>;
  }

  return (
    <div className="mx-4 md:mx-0">
      <div className="md:w-[50vw] mx-auto bg-mainDarkBrown p-4">
        {/* City Wall */}

        {/* City Area */}
        <div className="bg-mainLightYellow p-2 max-h-[74vh] min-h-0 overflow-auto">
          <div
            className={` ${
              cityWall &&
              "border-4 border-gray-700 rounded-lg p-3 bg-cover bg-center bg-gray-300"
            }`}
            style={
              cityWall
                ? {
                    backgroundImage: `url(${stoneTexture})`,
                    backgroundBlendMode: "overlay",
                    backgroundColor: "#A9A9A9", // Adjust to your preferred blend color
                  }
                : {}
            }
          >
            <div className="bg-mainLightYellow p-2">
              <div className="grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8 max-w-full scrollbar scrollbar-thumb-mainLightGreen2 scrollbar-thick p-4 ">
                {sortedBuildings &&
                  sortedBuildings.length > 0 &&
                  sortedBuildings.map((building, index) => (
                    <CityBuildingSpot key={index} building={building} />
                  ))}
              </div>
            </div>
            {/*Extra spots that can be given to city wall and for further buildings*/}
            {cityWall && <CityBuildingSpot building={cityWall} />}
          </div>
        </div>
      </div>
    </div>
  );
}
