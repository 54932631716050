import { useNavigate } from "react-router-dom";
import {useState, useEffect, useContext} from "react";
import {CityConfigContext} from "../../index";
import {BuildingInCityCard} from "../cards/BuildingInCityCard";

export function CityBuildingSpot(props) {
  const { setSelectedBuilding } = useContext(CityConfigContext);
  const navigate = useNavigate();
  const [buildingStatus, setBuildingStatus] = useState(null);

  useEffect(() => {
    if(props.building.status){
      setBuildingStatus(props.building.status);
    }
  }, [props.building.status]);

  function upgradeBuilding() {
    setSelectedBuilding(props.building)
    localStorage.setItem("selectedTownBuilding", JSON.stringify(props.building))
    navigate(`/buildings/upgrade`);
  }


  return (
    <div >

          <BuildingInCityCard building={props.building} upgradeBuilding={upgradeBuilding} buildingStatus={buildingStatus} buildingLocation={props.building.location}/>


    </div>
  );
}
