import { SmallWheatIcon } from "../../images/resources/SmallWheatIcon";
import { SmallWoodIcon } from "../../images/resources/SmallWoodIcon";
import { SmallStoneIcon } from "../../images/resources/SmallStoneIcon";
import { SmallIronIcon } from "../../images/resources/SmallIronIcon";
import { useTimerState } from "../contexts/timerContext";
import { useContext, useEffect, useState } from "react";
import { HelperFunctions } from "../../HelperFunctions";
import { CityConfigContext } from "../../index";
import { InstaBuildingCooldown } from "../InstaBuildingCooldown";
import { RULE_CONFIG } from "../../RULES";
import { CasinoCoinResponsive } from "../../images/CasinoCoinResponsive";
import { InstaBuildTokenButton } from "../InstaBuildTokenButton";
import { AllBuildingOptions } from "../AllBuildingOptions";

export function UpgradeBuildingReqCard({
  upgradedBuilding,
  upgradeBuilding,
  actualBuildTime,
  hasEnoughResources,
  isMaxLevel,
  building,
  bonuses,
}) {
  const allTimers = useTimerState();

  const { insta_builds_left, cityTokens } = useContext(CityConfigContext);

  const [buildingTimers, setBuildingTimers] = useState([
    ...allTimers.resourceTimers,
    ...allTimers.buildingTimers,
  ]);

  useEffect(() => {
    setBuildingTimers([
      ...allTimers.resourceTimers,
      ...allTimers.buildingTimers,
    ]);
  }, [allTimers]);

  return (
    <div className="flex justify-center text-center text-sm md:text-base">
      <div className="">
        {upgradedBuilding && (
          <div className="">
            <h4 className="text-base md:text-xl font-semibold mb-2">
              Upgrade to {upgradedBuilding.building_level}
            </h4>
            {building.building_name !== "Caligula's Palace" ? (
              <div className=" mb-4">
                <div className="grid grid-cols-4 gap-6 xxs:grid-cols-4  text-xxs xxs:text-xs  font-semibold">
                  <div className="flex">
                    <SmallWheatIcon />
                    <h4>{upgradedBuilding.wheat_req}</h4>
                  </div>
                  <div className="flex">
                    <SmallWoodIcon />
                    <h4>{upgradedBuilding.wood_req}</h4>
                  </div>
                  <div className="flex">
                    <SmallStoneIcon />
                    <h4>{upgradedBuilding.stone_req}</h4>
                  </div>
                  <div className="flex">
                    <SmallIronIcon />
                    <h4>{upgradedBuilding.iron_req}</h4>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mb-4">
                <div className="flex justify-center">
                  <CasinoCoinResponsive />
                  <h4>{upgradedBuilding.wheat_req}</h4>
                </div>
              </div>
            )}
            <h4 className="mb-4">
              {HelperFunctions.secondsToTimestamp(actualBuildTime)}
              {bonuses &&
                bonuses.building_speed_bonus &&
                bonuses.building_speed_bonus > 0 && (
                  <div>({bonuses.building_speed_bonus}% bonus)</div>
                )}
            </h4>
          </div>
        )}
        {upgradedBuilding && (
          <AllBuildingOptions
            bonuses={bonuses}
            build={upgradeBuilding}
            buildingTimers={buildingTimers}
            hasEnoughResources={hasEnoughResources}
            buildingId={upgradedBuilding.id}
          />
        )}
        {/* {hasEnoughResources &&
        buildingTimers.length <
          RULE_CONFIG.default_buildings_in_queue +
            bonuses.extra_queue_buildings &&
        !isMaxLevel ? (
          <div>
            <div className="flex space-x-4 justify-center">
              <button
                onClick={() =>
                  upgradeBuilding(upgradedBuilding.id, building.location, false)
                }
                className="bg-green-600 hover:bg-green-500 text-white font-semibold py-2 px-4 rounded transition duration-200"
              >
                Build
              </button>

              <InstaBuildingCooldown
                builds_left={insta_builds_left}
                build={() =>
                  upgradeBuilding(
                    upgradedBuilding.id,
                    building.location,
                    "normal"
                  )
                }
              />
              <InstaBuildTokenButton
                build={() =>
                  upgradeBuilding(
                    upgradedBuilding.id,
                    building.location,
                    "token"
                  )
                }
              />
            </div>

            {bonuses.insta_building_complete_chance > 0 && (
              <h4>
                Roll lower than {bonuses.insta_building_complete_chance} to
                insta build. Does not roll for building already in queue.
              </h4>
            )}
          </div>
        ) : buildingTimers.length < 2 && !isMaxLevel ? (
          <h3 className="text-red-500">Not enough resources</h3>
        ) : !isMaxLevel ? (
          <div>Building queue full</div>
        ) : (
          <></>
        )} */}
      </div>
    </div>
  );
}
