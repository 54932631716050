import { CITY_BUILDING_DESCRIPTIONS } from "../../constants";

export function BuildingDescriptionCard({ type }) {
  if (type === "Warehouse") {
    return <h4>{CITY_BUILDING_DESCRIPTIONS.Warehouse}</h4>;
  }
  if (type === "Barracks") {
    return <h4>{CITY_BUILDING_DESCRIPTIONS.Barracks}</h4>;
  }
  if (type === "Casino") {
    return <h4>{CITY_BUILDING_DESCRIPTIONS.Casino}</h4>;
  }
  if (type === "Marketplace") {
    return <h4>{CITY_BUILDING_DESCRIPTIONS.Marketplace}</h4>;
  }
  if (type === "Embassy") {
    return <h4>{CITY_BUILDING_DESCRIPTIONS.Embassy}</h4>;
  }
  if (type === "Mapchamber") {
    return <h4>{CITY_BUILDING_DESCRIPTIONS.Mapchamber}</h4>;
  }
  if (type === "Mill") {
    return <h4>{CITY_BUILDING_DESCRIPTIONS.Mill}</h4>;
  }
  if (type === "Smith") {
    return <h4>{CITY_BUILDING_DESCRIPTIONS.Smith}</h4>;
  }
  if (type === "Brothel") {
    return <h4>{CITY_BUILDING_DESCRIPTIONS.Brothel}</h4>;
  }
  if (type === "Lumberyard") {
    return <h4>{CITY_BUILDING_DESCRIPTIONS.Lumberyard}</h4>;
  }
  if (type === "Quarry") {
    return <h4>{CITY_BUILDING_DESCRIPTIONS.Quarry}</h4>;
  }
  if (type === "Wall") {
    return <h4>{CITY_BUILDING_DESCRIPTIONS.Wall}</h4>;
  }
  if (type === "Church") {
    return <h4>{CITY_BUILDING_DESCRIPTIONS.Church}</h4>;
  }
  if (type === "Caligula's Palace") {
    return <h4>{CITY_BUILDING_DESCRIPTIONS.CaligulasPalace}</h4>;
  }
}
