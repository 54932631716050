import { useContext } from "react";
import { CityConfigContext } from "..";
import { RULE_CONFIG } from "../RULES";
import { InstaBuildingCooldown } from "./InstaBuildingCooldown";
import { InstaBuildTokenButton } from "./InstaBuildTokenButton";

export function AllBuildingOptions({
  buildingTimers,
  bonuses,
  hasEnoughResources,
  build,
  buildingId,
}) {
  const { insta_builds_left } = useContext(CityConfigContext);

  return (
    <div>
      <div>
        <div className="flex space-x-4">
          {!hasEnoughResources ? (
            <h4 className="text-red-500 font-bold">Not enough resources</h4>
          ) : (
            <div>
              {buildingTimers.length <
              RULE_CONFIG.default_buildings_in_queue +
                bonuses.extra_queue_buildings ? (
                <button
                  onClick={() => build(buildingId, false)}
                  className="bg-green-600 hover:bg-green-500 text-white font-semibold py-2 px-4 rounded transition duration-200"
                >
                  Build
                </button>
              ) : (
                <h4 className="font-bold">Building queue full!</h4>
              )}
              <InstaBuildingCooldown
                build={() => build(buildingId, "normal")}
                builds_left={insta_builds_left}
              />
              <InstaBuildTokenButton build={() => build(buildingId, "token")} />
            </div>
          )}
        </div>
        {bonuses &&
          bonuses.insta_building_complete_chance &&
          bonuses.insta_building_complete_chance > 0 && (
            <h4>
              Roll lower than {bonuses.insta_building_complete_chance} to insta
              build. Does not roll for building already in queue.
            </h4>
          )}
      </div>
    </div>
  );
}
