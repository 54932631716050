import { useContext, useState } from "react";
import { CityConfigContext } from "../../index.jsx";
import { Link, useNavigate } from "react-router-dom";
import { timerActions } from "../reducers/timerReducer";
import { useTimerDispatch } from "../contexts/timerContext";
import { ClubmanResponsive } from "../../images/troops/ClubmanResponsive";
import { SpearmanResponsive } from "../../images/troops/SpearmanResponsive";
import { AxemanResponsive } from "../../images/troops/AxemanResponsive";
import { FounderIconResponsive } from "../../images/troops/FounderIconResponsive";
import { GuardianResponsive } from "../../images/troops/GuardianResponsive.jsx";
import { WarlordResponsive } from "../../images/troops/WarlordResponsive.jsx";

export function MapAttackCard(props) {
  const { troops, cityTokens } = useContext(CityConfigContext);
  const dispatch = useTimerDispatch();
  const [attackData, setAttackData] = useState({
    toCity: props.cityId,
    troops: {
      troop1: 0,
      troop2: 0,
      troop3: 0,
      troop4: 0,
      troop5: 0,
      troop6: 0,
    },
  });

  const [errorText, setErrorText] = useState();
  const navigate = useNavigate();
  const sendAttack = async (ignoreTroops) => {
    // validation
    let hasEnoughTroops = true;
    troops.forEach((troop) => {
      if (
        troop.troop_id === 1 &&
        attackData.troops.troop1 > troop.in_city_amount
      ) {
        hasEnoughTroops = false;
      }
      if (
        troop.troop_id === 2 &&
        attackData.troops.troop2 > troop.in_city_amount
      ) {
        hasEnoughTroops = false;
      }
      if (
        troop.troop_id === 3 &&
        attackData.troops.troop3 > troop.in_city_amount
      ) {
        hasEnoughTroops = false;
      }
      if (
        troop.troop_id === 4 &&
        attackData.troops.troop4 > troop.in_city_amount
      ) {
        hasEnoughTroops = false;
      }
      if (
        troop.troop_id === 5 &&
        attackData.troops.troop5 > troop.in_city_amount
      ) {
        hasEnoughTroops = false;
      }
      if (
        troop.troop_id === 6 &&
        attackData.troops.troop6 > troop.in_city_amount
      ) {
        hasEnoughTroops = false;
      }
    });

    if (!hasEnoughTroops) {
      setErrorText("You don't have enough troops");
      return;
    }
    if (ignoreTroops && cityTokens.raider_count <= 0) {
      setErrorText("You dont have any raider tokens.");
      return;
    }

    setErrorText("");
    if (
      attackData.troops.troop1 > 0 ||
      attackData.troops.troop2 > 0 ||
      attackData.troops.troop3 > 0 ||
      attackData.troops.troop4 > 0 ||
      attackData.troops.troop5 > 0 ||
      attackData.troops.troop6 > 0
    ) {
      let url;
      if (ignoreTroops) {
        url = `${
          import.meta.env.VITE_GAMEHOST
        }/api/troops/attack?ignore_troops=true`;
      } else {
        url = `${import.meta.env.VITE_GAMEHOST}/api/troops/attack`;
      }
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ attackData }),
        credentials: "include",
      });

      if (res.status === 200) {
        const data = await res.json();
        dispatch({
          type: timerActions.ADD_TIMER,
          category: "outgoingAttackTimer",
          payload: {
            duration: data.travelTimeMillies,
            timeLeft: Math.ceil(data.timeLeft / 1000),
            endTime: data.arrivalTime,
            attackId: data.attackId,
            detailsId: data.attackDetailsId,
            attackerCityId: data.attackerCity,
            attackerCityName: data.attackerCityname,
            defenderCityName: data.defenderCityname,
            attackerUsername: data.attackerUsername,
            defenderCityId: data.defenderCity,
            defenderUsername: data.defenderUsername,
            troop1: data.troopAttackedWithAmount.troop1,
            troop2: data.troopAttackedWithAmount.troop2,
            troop3: data.troopAttackedWithAmount.troop3,
            troop4: data.troopAttackedWithAmount.troop4,
            troop5: data.troopAttackedWithAmount.troop5,
            troop6: data.troopAttackedWithAmount.troop6,
          },
        });

        navigate("/resources");
      }
      if (res.status === 401) {
        const data = await res.text();
        setErrorText(data);
      }
    } else {
      setErrorText("You have to select atleast one troop");
    }
  };

  return (
    <div className="text-white">
      <button
        className="bg-mainAttack  px-4 py-2 rounded shadow-md hover:text-purple-600 focus:outline-none mb-2"
        onClick={props.hideMenu}
      >
        Go back
      </button>
      <h4 className="text-center text-xl font-semibold mb-2">
        Attack {props.targetCity} (
        <Link
          className="text-cyan-500 font-semibold hover:underline"
          to={`/profile/${props.targetUser}`}
        >
          {props.targetUser}
        </Link>
        )
      </h4>
      <div className="grid xxxs:grid-cols-2 sm:grid-cols-3 md:grid-cols-2 xl:grid-cols-6 ">
        {troops.map((troop) => (
          <div>
            {troop.troop_id === 1 && (
              <div className="">
                <ClubmanResponsive />
                <input
                  min={0}
                  max={troop.in_city_amount}
                  type="number"
                  className="w-12"
                  value={attackData.troops.troop1}
                  onChange={(e) =>
                    setAttackData((prevState) => ({
                      ...prevState,
                      troops: {
                        ...prevState.troops,
                        troop1: Number(e.target.value),
                      },
                    }))
                  }
                />
                Max{" "}
                <span
                  className="text-blue-500 hover:text-blue-700"
                  onClick={() =>
                    setAttackData((prevState) => ({
                      ...prevState,
                      troops: {
                        ...prevState.troops,
                        troop1: troop.in_city_amount,
                      },
                    }))
                  }
                >
                  ({troop.in_city_amount})
                </span>
              </div>
            )}

            {troop.troop_id === 2 && (
              <div className="">
                <SpearmanResponsive />
                <input
                  min={0}
                  max={troop.in_city_amount}
                  type="number"
                  className="w-12"
                  value={attackData.troops.troop2}
                  onChange={(e) =>
                    setAttackData((prevState) => ({
                      ...prevState,
                      troops: {
                        ...prevState.troops,
                        troop2: Number(e.target.value),
                      },
                    }))
                  }
                />
                Max{" "}
                <span
                  className="text-blue-500 hover:text-blue-700"
                  onClick={() =>
                    setAttackData((prevState) => ({
                      ...prevState,
                      troops: {
                        ...prevState.troops,
                        troop2: troop.in_city_amount,
                      },
                    }))
                  }
                >
                  ({troop.in_city_amount})
                </span>
              </div>
            )}

            {troop.troop_id === 3 && (
              <div className="">
                <AxemanResponsive />
                <input
                  min={0}
                  max={troop.in_city_amount}
                  type="number"
                  className="w-12"
                  value={attackData.troops.troop3}
                  onChange={(e) =>
                    setAttackData((prevState) => ({
                      ...prevState,
                      troops: {
                        ...prevState.troops,
                        troop3: Number(e.target.value),
                      },
                    }))
                  }
                />
                Max
                <span
                  className="text-blue-500 hover:text-blue-700"
                  onClick={() =>
                    setAttackData((prevState) => ({
                      ...prevState,
                      troops: {
                        ...prevState.troops,
                        troop3: troop.in_city_amount,
                      },
                    }))
                  }
                >
                  ({troop.in_city_amount})
                </span>
              </div>
            )}

            {troop.troop_id === 4 && (
              <div className="">
                <FounderIconResponsive />
                <input
                  min={0}
                  max={troop.in_city_amount}
                  type="number"
                  className="w-12"
                  value={attackData.troops.troop4}
                  onChange={(e) =>
                    setAttackData((prevState) => ({
                      ...prevState,
                      troops: {
                        ...prevState.troops,
                        troop4: Number(e.target.value),
                      },
                    }))
                  }
                />
                Max{" "}
                <span
                  className="text-blue-500 hover:text-blue-700"
                  onClick={() =>
                    setAttackData((prevState) => ({
                      ...prevState,
                      troops: {
                        ...prevState.troops,
                        troop4: troop.in_city_amount,
                      },
                    }))
                  }
                >
                  ( {troop.in_city_amount})
                </span>
              </div>
            )}
            {troop.troop_id === 5 && (
              <div className="">
                <GuardianResponsive />
                <input
                  min={0}
                  max={troop.in_city_amount}
                  type="number"
                  className="w-12"
                  value={attackData.troops.troop5}
                  onChange={(e) =>
                    setAttackData((prevState) => ({
                      ...prevState,
                      troops: {
                        ...prevState.troops,
                        troop5: Number(e.target.value),
                      },
                    }))
                  }
                />
                Max{" "}
                <span
                  className="text-blue-500 hover:text-blue-700"
                  onClick={() =>
                    setAttackData((prevState) => ({
                      ...prevState,
                      troops: {
                        ...prevState.troops,
                        troop5: troop.in_city_amount,
                      },
                    }))
                  }
                >
                  ( {troop.in_city_amount})
                </span>
              </div>
            )}
            {troop.troop_id === 6 && (
              <div className="">
                <WarlordResponsive />
                <input
                  min={0}
                  max={troop.in_city_amount}
                  type="number"
                  className="w-12"
                  value={attackData.troops.troop6}
                  onChange={(e) =>
                    setAttackData((prevState) => ({
                      ...prevState,
                      troops: {
                        ...prevState.troops,
                        troop6: Number(e.target.value),
                      },
                    }))
                  }
                />
                Max{" "}
                <span
                  className="text-blue-500 hover:text-blue-700"
                  onClick={() =>
                    setAttackData((prevState) => ({
                      ...prevState,
                      troops: {
                        ...prevState.troops,
                        troop6: troop.in_city_amount,
                      },
                    }))
                  }
                >
                  ( {troop.in_city_amount})
                </span>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="flex justify-between">
        <button
          className="bg-mainDefend px-4 py-2 rounded shadow-md hover:text-purple-600 focus:outline-none mt-4"
          onClick={() => sendAttack(false)}
        >
          Attack
        </button>
        {cityTokens.raider_count > 0 && (
          <div>
            <button
              className="bg-mainDefend px-4 py-2 rounded shadow-md hover:text-purple-600 focus:outline-none mt-4"
              onClick={() => sendAttack(true)}
            >
              Use raider token
            </button>
            <div>{cityTokens.raider_count}/1</div>
          </div>
        )}
      </div>
      {errorText && <div className="text-red-300 mt-2">{errorText}</div>}
    </div>
  );
}
