import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { BuildingMenuItem } from "../Components/town/BuildingMenuItem.jsx";
import { CasinoContext, CityConfigContext } from "../index.jsx";
import { CasinoCoinResponsive } from "../images/CasinoCoinResponsive.jsx";

export function BuildingMenuPage() {
  const { currentCasinoCoins } = useContext(CasinoContext);
  const { buildingSpotId } = useParams();
  const { cityBonus } = useContext(CityConfigContext);

  const [buildingConfig, setBuildingConfig] = useState(null);
  const [buildingSpot, setBuildingSpot] = useState();

  async function getBuildings() {
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/config/buildings`,
      {
        credentials: "include",
      }
    );

    const data = await res.json();

    setBuildingConfig(data);
  }
  useEffect(() => {
    getBuildings();
    setBuildingSpot(buildingSpotId);
  }, []);

  if (buildingConfig == null) {
    return <div>Loading..</div>;
  }

  return (
    <div className="w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] overflow-hidden">
      <div className="relative  top-[10vh] mx-4">
        <div className="md:w-[50vw] mx-auto bg-mainDarkBrown p-4">
          {cityBonus.insta_building_complete_chance > 0 ? (
            <div className="flex justify-between">
              <h4>
                Roll lower than {cityBonus.insta_building_complete_chance} to
                insta build. Does not roll for building already in queue.
              </h4>
              <div className="flex justify-end">
                <p className="mr-2">{currentCasinoCoins}</p>
                <CasinoCoinResponsive />
              </div>
            </div>
          ) : (
            <div className="flex justify-end">
              <p className="mr-2">{currentCasinoCoins}</p>
              <CasinoCoinResponsive />
            </div>
          )}

          <div className="grid grid-cols-1 xxxs:grid-cols-1 xs:grid-cols-2  2xl:grid-cols-3 gap-8 max-w-full scrollbar scrollbar-thumb-blue-500 scrollbar-thick bg-mainLightYellow p-4  max-h-[70vh] min-h-0 overflow-auto">
            {buildingConfig.allBuildings.map((building, index) => (
              <BuildingMenuItem
                building={building}
                buildingSpot={buildingSpot}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
