import { useContext } from "react";
import { CityConfigContext } from "../../index";
import { NextTroopInQueueModal } from "../cards/NextTroopInQueueModal";
import { NextFounderInQueueModal } from "../cards/NextFounderInQueueModal";
import { ClubmanResponsive } from "../../images/troops/ClubmanResponsive";
import { SpearmanResponsive } from "../../images/troops/SpearmanResponsive";
import { AxemanResponsive } from "../../images/troops/AxemanResponsive";
import { FounderIconResponsive } from "../../images/troops/FounderIconResponsive";
import { GuardianResponsive } from "../../images/troops/GuardianResponsive";
import { WarlordResponsive } from "../../images/troops/WarlordResponsive";

export function TroopsOverview() {
  const { troops } = useContext(CityConfigContext);

  if (!troops) {
    return <div>You have no troops in this city</div>;
  }

  return (
    <div className="bg-mainDarkBrown text-white p-2 min-w-[170px] sm:min-w-[170px] md:min-w-full lg:min-w-auto xl:min-w-auto">
      <h2 className="font-fantasy text-xs lg:text-base xxl:text-xl text-white font-semibold mb-2 sm:mb-4 md:mb-4 lg:mb-4 xl:mb-4 text-center">
        Troops
      </h2>
      <div className="p-2 sm:p-3 md:p-3 lg:p-6 bg-mainLightBrown1 text-black overflow-auto max-h-[20vh] scrollbar scrollbar-thumb-cityCard3 scrollbar-thin">
        <ul className="space-y-1 sm:space-y-2 md:space-y-2 lg:space-y-2 xl:space-y-2 font-pixel grid grid-cols-2 md:grid-cols-1">
          {troops.map((troop) => (
            <li key={troop.troop_id} className="flex flex-col md:flex-row ">
              {troop.troop_id === 1 && troop.in_city_amount > 0 && (
                <ClubmanResponsive />
              )}
              {troop.troop_id === 2 && troop.in_city_amount > 0 && (
                <SpearmanResponsive />
              )}
              {troop.troop_id === 3 && troop.in_city_amount > 0 && (
                <AxemanResponsive />
              )}
              {troop.troop_id === 4 && troop.in_city_amount > 0 && (
                <FounderIconResponsive />
              )}
              {troop.troop_id === 5 && troop.in_city_amount > 0 && (
                <GuardianResponsive />
              )}
              {troop.troop_id === 6 && troop.in_city_amount > 0 && (
                <WarlordResponsive />
              )}
              {troop.in_city_amount > 0 && (
                <h2 className="text-base sm:text-base md:text-sm lg:text-base xl:text-lg">
                  {troop.in_city_amount}
                </h2>
              )}
            </li>
          ))}
        </ul>
      </div>
      <NextTroopInQueueModal />
      <NextFounderInQueueModal />
    </div>
  );
}
