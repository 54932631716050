import { MapChamber } from "../../buildingInfos/MapChamber";
import { Embassy } from "../../buildingInfos/Embassy";
import { Marketplace } from "../../buildingInfos/Marketplace";
import { CasinoCard } from "../../buildingInfos/CasinoCard";

import { BuildingDescAndImage } from "../../cards/BuildingDescAndImage";
import { Barracks } from "../../buildingInfos/Barracks";
import { Church } from "../../buildingInfos/Church";
import DemolishIcon from "../../../images/DemolishBuildingIcon.png";
import { Wall } from "./Wall";

export function BuildingInfo({
  building,
  isMax,
  hideUpgradeMenu,
  upgradedBuilding,
  setShowDemolishModal,
}) {
  return (
    <div>
      <h2 className=" text-lg md:text-xl xl:text-2xl font-bold mb-4 mt-4 text-center place-items-center flex justify-center">
        {building.building_name} Level:{" "}
        {!isMax ? (
          <div className="inline">
            {" "}
            {building.status ? (
              <div className="inline">{building.status}</div>
            ) : (
              <div className="inline">{building.building_level}</div>
            )}
          </div>
        ) : (
          <div className="inline">Max!</div>
        )}
        {building.building_name !== "Wall" && (
          <div className="inline">
            <img
              src={DemolishIcon}
              alt="Demolish building icon"
              onClick={() => setShowDemolishModal(true)}
              className="cursor-pointer p-2 text-white rounded focus:outline-none focus:ring-2 focus:ring-opacity-50"
            />
          </div>
        )}
      </h2>

      {/*Building has tabs, so the building info and image is inside one of the tabs*/}

      {building.building_name === "Marketplace" && (
        <Marketplace hideUpgradeMenu={hideUpgradeMenu} />
      )}
      {building.building_name === "Casino" && (
        <CasinoCard hideUpgradeMenu={hideUpgradeMenu} />
      )}
      {building.building_name === "Barracks" && (
        <Barracks
          level={building.building_level}
          hideUpgradeMenu={hideUpgradeMenu}
        />
      )}
      {building.building_name === "Mapchamber" && (
        <MapChamber
          level={building.building_level}
          hideUpgradeMenu={hideUpgradeMenu}
        />
      )}
      {building.building_name === "Embassy" && (
        <Embassy
          level={building.building_level}
          hideUpgradeMenu={hideUpgradeMenu}
        />
      )}
      {building.building_name === "Church" && (
        <Church
          level={building.building_level}
          hideUpgradeMenu={hideUpgradeMenu}
        />
      )}
      {building.building_name === "Wall" && (
        <Wall
          level={building.building_level}
          hideUpgradeMenu={hideUpgradeMenu}
        />
      )}

      {/*No component for specific building*/}

      {building.building_name === "Mill" && (
        <BuildingDescAndImage type="Mill" isDetails={true} />
      )}
      {building.building_name === "Smith" && (
        <BuildingDescAndImage type="Smith" isDetails={true} />
      )}
      {building.building_name === "Lumberyard" && (
        <BuildingDescAndImage type="Lumberyard" isDetails={true} />
      )}
      {building.building_name === "Quarry" && (
        <BuildingDescAndImage type="Quarry" isDetails={true} />
      )}
      {building.building_name === "Warehouse" && (
        <BuildingDescAndImage
          type="Warehouse"
          isDetails={true}
          level={building.building_level}
          upgradedBuilding={upgradedBuilding}
        />
      )}
      {building.building_name === "Brothel" && (
        <BuildingDescAndImage type="Brothel" isDetails={true} />
      )}
      {building.building_name === "Caligula's Palace" && (
        <BuildingDescAndImage type="Caligula's Palace" isDetails={true} />
      )}
    </div>
  );
}
